exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dropdown-js": () => import("./../../../src/pages/dropdown.js" /* webpackChunkName: "component---src-pages-dropdown-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-home-right-js": () => import("./../../../src/pages/homeRight.js" /* webpackChunkName: "component---src-pages-home-right-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-menu-projects-js": () => import("./../../../src/pages/menuProjects.js" /* webpackChunkName: "component---src-pages-menu-projects-js" */),
  "component---src-pages-navbar-js": () => import("./../../../src/pages/navbar.js" /* webpackChunkName: "component---src-pages-navbar-js" */),
  "component---src-pages-other-js": () => import("./../../../src/pages/other.js" /* webpackChunkName: "component---src-pages-other-js" */),
  "component---src-pages-other-right-js": () => import("./../../../src/pages/otherRight.js" /* webpackChunkName: "component---src-pages-other-right-js" */),
  "component---src-pages-play-js": () => import("./../../../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-play-right-js": () => import("./../../../src/pages/playRight.js" /* webpackChunkName: "component---src-pages-play-right-js" */),
  "component---src-pages-print-js": () => import("./../../../src/pages/print.js" /* webpackChunkName: "component---src-pages-print-js" */),
  "component---src-pages-print-right-js": () => import("./../../../src/pages/printRight.js" /* webpackChunkName: "component---src-pages-print-right-js" */)
}

